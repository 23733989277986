import Glide from '@glidejs/glide';

let hamburgerButon = document.querySelector('.hamburger');
let dropdown = document.querySelector('.dropdown');
let header = document.querySelector('.header');
var menuIsOpen = false;
let menuOption= document.querySelectorAll('.main-nav-item');

let opcionesSlides = {
  keyboard: true,
  autoplay: 4300,
  animationDuration: 1900,
}

var analisis = new Glide('.glide', opcionesSlides)
var prediccion = new Glide('.glide2', opcionesSlides)
var ocr = new Glide('.glide3', opcionesSlides)
var lenguaje= new Glide('.glide4', opcionesSlides)
  analisis.mount();
  prediccion.mount();
  ocr.mount();
  lenguaje.mount();


hamburgerButon.addEventListener("click", ()=>{
  toggleMenu()  
});

//fix para el menu movil
menuOption.forEach(function(menuItem, i){
  menuItem.addEventListener('click', function(){
    if(menuIsOpen){
      header.classList.toggle('active');
      //console.log('menu toggled '+i);
      menuIsOpen = false;
    };
  });
});



function toggleMenu(){
  menuIsOpen = !menuIsOpen;
  header.classList.toggle('active');
  //console.log(menuIsOpen);
}

